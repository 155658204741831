.createKeyResultContainer{
    padding: 0px 25px 25px 25px;
}

.createKeyResultForm{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
    width: 90%;
}

.loadedKeyResultsContainer {
    display: flex;
    gap: 16px;
    padding-bottom: 64px;
}

.createKeyResultFormItem {
    width: 40%;
}

.createKeyResultFormItemFullWidth{
    width: 90%;
}

.createdKeyResultLabel {
    border-radius: 5px;
    background-color: #DEFFD6;
    padding: 16px 10px;
    text-align: center;
    margin-bottom: 24px;
}