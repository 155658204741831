@tailwind base;
@tailwind components;
@tailwind utilities;

// PATHS
$font-path: "./assets/fonts" !default;

// FUENTES
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url('#{$font-path}/Poppins-Regular.ttf');
}

@font-face {
  font-family: "PaytoneOne";
  src: local("PaytoneOne"), url('#{$font-path}/paytone-one.regular.ttf');
}