.optionContainer {
    margin-top: 15px;
}

.optionContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
}

.optionItemName{
    display: flex;
    align-items: center;
    gap: 15px;
}

.keyResultsOptions{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.krItemContainer{
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    margin-left: 20px;
}

.krIcon {
    background-color: #384FF0;
    color: #FFF;
    padding: 10px;
    border-radius: 5px;
}