$gray-500: hsl(var(--nextui-default-100));
$primary: hsl(var(--nextui-primary));
$white: hsl(var(--nextui-primary-foreground));
$black: hsl(var(--nextui-foreground));

.stepsContainer {
    display: flex;
    align-items: center;
    height: 100px;
    padding-bottom: 50px;

    .stepLine {
        height: 5px;
        width: 100px;
        background-color: $gray-500;

        &.active {
            background-color: $primary;
        }

        &.first {
            border-radius: 15px 0px 0px 15px;
        }

        &.last {
            border-radius: 0px 15px 15px 0px;
        }
    }

    .stepContent{
        position: relative;

        .stepCircle {
            width: 40px;
            height: 40px;

            border-radius: 50%;
            background-color: $gray-500;
            
            &.active {
                background-color: $primary;
            }

            .stepCounter{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-weight: bold;
                font-size: 18px;
                color: $white;
            }
        }

        .stepTitle {
            position: absolute;
            width: 150px;
            text-align: center;
            left: -53px;
            top: 45px;
            color: $gray-500;

            &.active {
                color: $black;
            }
        }
    }
}

/*

.stepsContainer {
    display: flex;
    align-items: center;
    height: 100px;
    padding-bottom: 50px;
}

.stepLine {
    height: 5px;
    width: 100px;
    background-color: $white;
}

.stepLine.active {
    background-color: $primary;
}

.stepLine.first {
    border-radius: 15px 0px 0px 15px;
}

.stepLine.last {
    border-radius: 0px 15px 15px 0px;
}

.stepContent {
    position: relative;
}

.stepCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $gray-500;
}

.stepCircle.active {
    background-color: $primary;
}

.stepCounter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: 18px;
    color: $white;
}

.stepTitle {
    position: absolute;
    width: 150px;
    text-align: center;
    left: -53px;
    top: 45px;
    color: $gray-500;
}
*/