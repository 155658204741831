.optionsContainer { 
    background-color: #FBFAFA;
    border-radius: 15px;
}

.optionItem {
}

.valuesContainer {
    display: flex;
    gap: 10px
}

.valueItem {
    background-color: #384FF0;
    border-radius: 10px;
    padding: 5px 10px;
    color: white;
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 10px;
}

.times {
    cursor: pointer;
}

.avatarItemContainer{
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 25px
}

.avatarImageContainer {
    color: #384FF0;
    border-radius: 50%;
    width: 40px;
}

.userInfoContainer{

}

.userFirstName{
    font-size: 16px;
    font-weight: bold;
}

.userEmail {
    font-size: 12px;
}

.selectButtonContainer {

}

.selectButton {
    padding: 10px 5px;
}

.avatarItemContainer{
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 25px
}

.userInfoContainer{

}

.userFirstName{
    font-size: 16px;
    font-weight: bold;
}

.userEmail {
    font-size: 12px;
}

.selectButtonContainer {

}

.selectButton {
    padding: 10px 5px;
}