.optionsContainer { 
    background-color: #FBFAFA;
    border-radius: 15px;
}

.optionItem {
}

.valuesContainer {
    display: flex;
    gap: 10px
}

.valueItem {
    background-color: #384FF0;
    border-radius: 10px;
    padding: 5px 10px;
    color: white;
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 10px;
}

.times {
    cursor: pointer;
}