.createObjectiveContainer{
    padding: 0px 25px 25px 25px;
}

.createObjectiveForm{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
    width: 90%;
}

.createObjectiveFormItem {
    width: 40%;
}

.createObjectiveFormItemFullWidth{
    width: 90%;
}